<template>
  <div>
    <CarCategory />
    <v-row justify="space-between">
        <v-col cols="12" md="6">
            <v-tabs v-model="tab">
            <v-tabs-slider></v-tabs-slider>

     

            <v-tab href="#tab-2">
                    옵션
            </v-tab>
             <v-tab href="#tab-3">
                    json
            </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tab">
                
                <v-tab-item
                    value="tab-2"
                >
                    <CarOptions />
                </v-tab-item>
                <v-tab-item
                    value="tab-3"
                >
                    {{this.$store.getters.carOptions.OPTIONS}}
                </v-tab-item>

            
            </v-tabs-items>

            
        </v-col>
         <v-col cols="12" md="6">
             옵션선택 이후의 결과값은 아직 분석중..
             <br />
             멀티선택(자동해제 등)은 EVENT_CD_ADD 값으로 확인
         </v-col>
    </v-row>
  </div>
</template>

<script>
import CarCategory from '@/components/site/modeal/CarCategory'
import CarOptions from '@/components/site/modeal/CarOptions'
  export default {
    name: 'HelloWorld',
    components: {
        CarCategory,
        CarOptions


    },
    data () {
      return {
        tab: null,
      }
    },
  }
</script>
