<template>
  
   
    

    <v-list
      flat
      subheader
      three-line
    >


      <v-list-item-group
        v-model="settings"
        multiple
        active-class=""
      >

        <v-list-item v-for="(item,index) in this.$store.getters.modealInfo.options" :key="index">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              
              <v-list-item-title>{{item.OPTION_NM}}</v-list-item-title>
              <v-list-item-subtitle >{{item.OPTION_RMRK}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                  {{item.ADD_OPTION_AMT}}만원
              </v-list-item-action>
          </template>
        </v-list-item>

        
      </v-list-item-group>
      
    </v-list>
    
</template>

<script>
  export default {
      
    data () {
      return {
        valid: true,
          options: this.$store.getters.carOptions,
        settings: [],
        select: { state: 'Florida', abbr: 'FL' },
        items: [
          { state: 'Florida', abbr: 'FL' },
          { state: 'Georgia', abbr: 'GA' },
          { state: 'Nebraska', abbr: 'NE' },
          { state: 'California', abbr: 'CA' },
          { state: 'New York', abbr: 'NY' },
        ],
        formData: {
          priceList: [
            {text: '0% | 0원', value: 0}
          ],
        },
        forms: {
          periodOfUse:0,//이용기간
          advancePayment:0,//선수금
          deposit:0,//보증금
          residualValue:0,//잔존가치
          contractDistance:0,//약정계약
          area:0,//지역
        },
        publicBondPurchaseRateData:[//공공채권 매입율
          {
            area: '서울', kind: '승용',number: 2,
            cc: [
              { percent: true, cc: 1000, value: 0.09 },
              { percent: true, cc: 1500, value: 0.09 },
              { percent: true, cc: 1600, value: 0.12 },
              { percent: true, cc: 2000, value: 0.2 },
            ]
          },
          {
            area: '서울', kind: 'RV(5인이하)',number: 3,
            cc: [
              { percent: true, cc: 1000, value: 0.05 },
              { percent: true, cc: 1500, value: 0.05 },
              { percent: true, cc: 1600, value: 0.05 },
              { percent: true, cc: 2000, value: 0.05 },
            ]
          },
          {
            area: '서울', kind: 'RV(7-10인)',number: 4,
            cc: [
              { percent: false, cc: 1000, value: 390000 },
              { percent: false, cc: 1500, value: 390000 },
              { percent: false, cc: 1600, value: 390000 },
              { percent: false, cc: 2000, value: 390000 }
            ]
          }
        ],
        result: {
          
          info: {
            cc: 3470,//배기량
            dcCode: 'D',//내수구분코드
            stCode: '',//전략차종코드
            tyCode: 'M',//유종코드
            carType: '승용',//공채차종구분
            seat: 5,//인승
            bq7: 0.07,//취득세율
            fuelCode: 'M',
            acquisitionTax: 0,//취득세
            publicBondPurchase: 0,//공채가격
            publicBondPurchaseRate: 0.12,//공채할인율-내부자료
            outherAmount: 239100,//부대비용 //todo
            
            advancePayment: 0,//선수금 //todo
            leasePrincipal: 0,//리스원금 = 계산서가격합계 + 취득세 + 공채 + 부대비용
            acquisitionCost: 0, // 취득원가 = 리스원금 + 선수금

          },
          vehiclePrice: {
            totalAmount: 0,//차량가격
            supplyPrice: 0,//공급가격=round(차량가격/1.1)
            vta: 0,//부가세 = 차량가격-공급가격
            consignmentFee: 119000,//제조사탁송료
            publicBondPurchaseRate: 12,//공채할인율
          },
          billPrice: {//계산서 가격
            totalAmount: 0,//vehiclePrice의 합계금액
            supplyPrice: 0,//공급가격=round(차량가격/1.1)
            vta: 0,//부가세 = 차량가격-공급가격
          }
          

          

        }
      }
    },
    methods: {
      acquisitiontaxSum() {
        //취득세
        //계산서가 공급가
        
      },
      shinhanSum() {
       // this.$store.getters.carPrice.totalPrice
       /*
        const rprice2 = 20000000;
        this.result.vehiclePrice.totalAmount = rprice2;//차량가격
        this.result.vehiclePrice.supplyPrice = Math.round(rprice2/1.1);//공급가격
        this.result.vehiclePrice.vta = this.result.vehiclePrice.totalAmount-this.result.vehiclePrice.supplyPrice;//부가세

        //계산서가
        this.result.billPrice.totalAmount = this.result.vehiclePrice.supplyPrice + this.result.vehiclePrice.vta + this.result.vehiclePrice.consignmentFee;//차량가격
        this.result.billPrice.supplyPrice = Math.round(this.result.billPrice.totalAmount/1.1);//공급가격
        this.result.billPrice.vta = this.result.billPrice.totalAmount-this.result.billPrice.supplyPrice;//부가세

        this.$log.warn('-------------차량가격------------------------')
        this.$log.warn('차량가격',this.result.vehiclePrice.totalAmount);
        this.$log.warn('공급가격',this.result.vehiclePrice.supplyPrice, '---과세반영', this.result.billPrice.supplyPrice);
        this.$log.warn('부가세',this.result.vehiclePrice.vta);
        this.$log.warn('제조사탁송료',this.result.vehiclePrice.consignmentFee);
        this.$log.warn('-------------계산서가------------------------')
        this.$log.warn('차량가격',this.result.billPrice.totalAmount);
        this.$log.warn('공급가격',this.result.billPrice.supplyPrice);
        this.$log.warn('부가세',this.result.billPrice.vta);
        this.$log.warn('result', this.result);
        this.$log.warn('-------------------------------------')
        this.$log.warn('-------------계산서가------------------------')
        
        this.result.info.acquisitionTax = this.result.billPrice.supplyPrice * this.result.info.bq7;
         this.$log.warn('-------취득세------------------------',this.result.info.acquisitionTax)
        this.result.info.acquisitionTax = Math.floor(this.result.info.acquisitionTax / 10) * 10; //원단위 버림
        
        this.result.info.publicBondPurchase = this.result.billPrice.supplyPrice * this.result.info.publicBondPurchaseRate * (this.result.vehiclePrice.publicBondPurchaseRate * 0.01)
        this.$log.warn('공채1',this.result.info.publicBondPurchase);
        this.result.info.publicBondPurchase = Math.ceil(this.result.info.publicBondPurchase / 10) * 10;//원단위 올림

        
        this.$log.warn('취득세',this.result.info.acquisitionTax);
        this.$log.warn('공채',this.result.info.publicBondPurchase);
        this.$log.warn('-------------리스원가------------------------')
        this.$log.warn('계산서가격',this.result.billPrice.totalAmount);
        this.$log.warn('취득세',this.result.info.acquisitionTax);
        this.$log.warn('공채',this.result.info.publicBondPurchase);
        this.$log.warn('부대비용',this.result.info.outherAmount);
        //리스원금=계산서가격합계 + 취득세 + 공채 + 부대비용
        //취득원가 = 리스원금 + 선수금
        this.result.info.leasePrincipal = this.result.billPrice.totalAmount + this.result.info.acquisitionTax + this.result.info.publicBondPurchase + this.result.info.outherAmount + 5000;
        this.result.info.acquisitionCost = this.result.info.leasePrincipal + this.result.info.advancePayment
        this.$log.warn('리스원금',this.result.info.leasePrincipal);
        this.$log.warn('취득원가',this.result.info.acquisitionCost);
        */
        this.$log.warn('-------------------------------------')
      }
    },
    computed: { getN () { return this.$store.getters.carPrice.totalPrice } },


    watch: {
        getN (val, oldVal) { 
          const rprice=val * 10000;
          const newPriceList = []
          for (let step = 1; step <= 40; step++) {
            
            // Runs 5 times, with values of step 0 through 4.
            console.log('Walking east one step');
            const tmpval = Math.round(rprice * step / 100)
            const payload = {
              text: String(step) + '% | ' +  String(tmpval).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '원',
              value: tmpval
            }
            newPriceList.push(payload);
            // this.formData.priceList.push(payload);
            if (step==1) {
              this.$log.warn('1%:',tmpval)
            }
          }
          this.$log.warn('oldVal',oldVal)
          this.formData.priceList = newPriceList;
          this.shinhanSum();
          //차량가격
          /*
          const rprice2 = 20000000;
          
          
          this.$log.warn('-------------------------------------')
          this.$log.warn('차량가격',rprice2);
          this.$log.warn('공급가격',Math.round(rprice2/1.1));
          this.$log.warn('부가세',rprice2-Math.round(rprice2/1.1));
          this.$log.warn('-------------------------------------')
          */
        },


        // 질문이 변경될 때 마다 이 기능이 실행됩니다.
        settings(new_value) {
            // Small subset of code, actual code does much more than this
            console.log('setting chage start--------------')

            const payload = {
                optionPrice: 0,
                optionCount: 0
            }
            for (const item of new_value) {
                payload.optionPrice += parseInt(this.$store.getters.carOptions.OPTIONS[item].ADD_OPTION_AMT)
                payload.optionCount +=1
               
            }
            this.$store.dispatch('carPriceOption',payload)
        },
        /*'$store.getters.carPrice': {
            deep: true,
            handler(){
               
                 this.$log.warn('watch this.state.app.carPrice.totalPrice',this.state.app.carPrice.totalPrice)
                
            }
        },
        */
        
    },
  }
</script>